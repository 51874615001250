import { Component, Inject } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'app-custom-copy-snackbar',
  templateUrl: './custom-copy-snackbar.component.html',
  styleUrls: ['./custom-copy-snackbar.component.scss']
})
export class CustomCopySnackbarComponent {
  emptyFunction = Function();
  AppConstant = AppConstant;
  isShowClickText = false;
  afterClickText = 'The Reference ID have copied. Closing in 5 second...';
  firstLineText = AppConstant.CT_EMPTY;
  secondLineText = AppConstant.CT_EMPTY;
  docId = AppConstant.CT_EMPTY;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<CustomCopySnackbarComponent>
  ) {
    this.onLoadInit();
  }

  public onLoadInit(): void {
    this.firstLineText = this.data.firstLine;
    this.secondLineText = this.data.secondLine;
    this.docId = this.data.docId;
  }

  public onClickCopy(): void {
    this.copyText(this.secondLineText);
    this.isShowClickText = true;
    setTimeout(() => {
      this.snackBarRef.dismiss();
    }, 5000);
    clearTimeout();
  }

  public copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}

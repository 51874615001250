// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyD5a-IZ3X_k2UnOAqyJwdDxR9HuZg5jM-s',
    authDomain: 'etronix-company-website.firebaseapp.com',
    databaseURL: 'https://etronix-company-website.firebaseio.com/',
    projectId: 'etronix-company-website',
    storageBucket: 'etronix-company-website.appspot.com',
    messagingSenderId: '1099313597786',
    appId: '1:1099313597786:web:4e745c0916c7c9aabf3763',
    measurementId: 'G-46ZC4KDF1J'
  },

  captchaProd: {
    siteKey: '6LeP07kdAAAAAF6z0i2hQovIUjBG46kbc2SF1sUU',
    secretKey: '6LeP07kdAAAAAL6aC-cjLfanzMHpM4ZzQdVjvSuz'
  },

  captchaTest: {
    // localhost test
    siteKey: '6LdOEf8cAAAAAO8HC37HfqZozqjnAwZzHSlV5zJF',
    secretKey: '6LdOEf8cAAAAAJSUbkNbW4D-0Ue8UA6ANYglBu8I'
  }
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@app-helpers/dialog/dialog.component';
import { AppConstant } from '@app/app.constant';

@Injectable()
export class ErrorDialogService {
  dialogRef: MatDialogRef<any>;
  constructor(private dialog: MatDialog) {}

  public promptAlertMessage(data) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: [AppConstant.CT_EMPTY, data, AppConstant.CT_OK, AppConstant.CT_EMPTY, AppConstant.CFG_DIALOG_ALERT],
      width: AppConstant.CT_PERCENTAGE_100,
      height: AppConstant.CT_PERCENTAGE_50,
      restoreFocus: AppConstant.CT_FALSE
    });
  }

  public promptInfoMessage(data) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: [AppConstant.CT_EMPTY, data, AppConstant.CT_OK, AppConstant.CT_EMPTY, AppConstant.CFG_DIALOG_INFO],
      width: AppConstant.CT_PERCENTAGE_100,
      height: AppConstant.CT_PERCENTAGE_50,
      restoreFocus: AppConstant.CT_FALSE
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AppConstant } from '@app/app.constant';

@Component({
  selector: 'app-header-standard',
  templateUrl: './header-standard.component.html',
  styleUrls: ['./header-standard.component.scss']
})
export class HeaderStandardComponent implements OnInit {
  @Input() title: any;
  @Input() detail: any;
  @Input() action: any;
  @Input() transact: any;
  actionText = AppConstant.CT_EMPTY;
  constructor() {}

  ngOnInit() {}
}

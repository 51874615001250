import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vcard',
  templateUrl: './vcard.component.html',
  styleUrls: ['./vcard.component.scss']
})
export class VcardComponent implements OnInit {
  public username: string;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.username = this.activatedRoute.snapshot.paramMap.get('username');
    console.log(this.username);
  }

}

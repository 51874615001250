import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app-env/environment';
import * as CommonModule from '@app-models/common.model';
import { DataService } from '@app-helpers/data.service';
import { HandlerService } from '@app-services/handler.service';
import { AccessCode } from '@app/access.code';
import { AppConstant } from '@app/app.constant';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as CommonModel from '@app-models/common.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private http: HttpClient,
    private _handler: HandlerService,
    private dataService: DataService,
    private firestore: AngularFirestore
  ) {}

  public GetPrivacyPolicy(): Observable<any> {
    const url = `../assets/dummyData/login/privacy-policy.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetCookiePolicy(): Observable<any> {
    const url = `../assets/dummyData/login/cookie-policy.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetTermOfUse(): Observable<any> {
    const url = `../assets/dummyData/login/term-of-use.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetCopyright(): Observable<any> {
    const url = `../assets/dummyData/login/copyright.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetAboutDescription(): Observable<CommonModule.IAbout> {
    const url = `../assets/dummyData/about.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetPartners(): Observable<CommonModule.IPartners> {
    const url = `../assets/dummyData/partners.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetContact(): Observable<CommonModule.IContact> {
    const url = `../assets/dummyData/contact.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetClientsList(): Observable<any> {
    const url = `../assets/dummyData/valueble-client.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetTechPartnersList(): Observable<any> {
    const url = `../assets/dummyData/technology-partner.json`;
    return this.http
      .get(url)
      .pipe(
        retry(AppConstant.API_RETRY),
        map(this._handler.extractData),
        catchError(this._handler.handleErrorObservable)
      );
  }

  public GetCareerData(): Observable<any[]> {
    const booksCollectionRef = this.firestore.collection('career-db', (ref) => ref.orderBy('jobTitle', 'asc'));
    return booksCollectionRef.valueChanges();
  }

  public CreateCareerApply(data: CommonModel.ICareerApply) {
    return this.firestore.collection('career-apply-db').add(data);
  }

  public getContatPageInfo() {
    return this.firestore.collection('contact-db').snapshotChanges();
  }

  public sendContactForm(data) {
    return this.firestore.collection('contact-submit-db').add(data);
  }

  public GetCompanyPageData(): Observable<any[]> {
    return this.firestore.collection('company-page-db').valueChanges();
  }

  public GetNewsEventsData(): Observable<any[]> {
    return this.firestore.collection('news-events-db').valueChanges();
  }

  public GetUpcomingEventsData(): Observable<any[]> {
    return this.firestore.collection('upcoming-events-db').valueChanges();
  }

  public GetSiteMapData(): Observable<any[]> {
    return this.firestore.collection('site-map-db').valueChanges();
  }

  public sendRequestDemo(data) {
    return this.firestore.collection('request-demo-apply-db').add(data);
  }

  public sendPartnerApply(data) {
    return this.firestore.collection('partner-apply-db').add(data);
  }

  public getHomePageBussiness() {
    return this.firestore.collection('home-bussiness-db').snapshotChanges();
  }

  public getHomeTestimonial() {
    return this.firestore.collection('home-testimonial-db').snapshotChanges();
  }
}

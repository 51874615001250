export class AppConstant {
  /* Session Storage */
  public static SS_CUR_USR = 'currentUser';
  public static SS_TOKEN = 'token';
  public static SS_USER = 'user';
  public static SS_FORGETUSER = 'forgetuser';
  public static SS_APPID = 'appid';
  public static SS_TITLE = 'title';
  public static SS_ENTERPRISEID = 'enterpriseid';
  public static SS_ENTERPRISENAME = 'enterprisename';
  public static SS_CODE = 'code';
  public static SS_LINKDATA = 'linkdata';
  /* Session Storage */

  /* Request Header */
  public static RH_SYSTEMID = '';
  public static RH_ACCESSCODE = '';
  public static RH_UUID = '';
  public static RH_TYPE = 'W';
  public static RH_ENTERPRISE = 'SKPBM';
  /* Request Header */

  /* API URL */
  public static API_RETRY = 3;
  public static API_LOGIN = 'api/Gateway/Login';
  public static API_GET = 'api/Gateway/Get';
  public static API_POST = 'api/Gateway/Post';
  public static API_PUT = 'api/Gateway/Put';
  public static API_ENTERPRISE = 'api/Enterprise/GetEnterprises';
  public static API_CONFIGURATION = 'api/Configuration';
  public static GET = 'Get';
  public static POST = 'Post';
  public static PUT = 'Put';

  /* API URL */

  /* SYSTEM CODE */
  public static SC_SSO = 'SSO';
  public static SC_MES = 'MES';
  public static SC_CFG = 'CFG';
  public static SC_WMS = 'WMS';
  /* SYSTEM CODE */

  /* URL Path */
  public static UP_HOME = 'home';
  public static UP_LOG_IN = 'login';
  public static UP_LOG_OUT = 'logout';
  public static UP_UNAUTHORIZE = 'unauthorize';
  public static UP_FGT_PWD = 'forgot-password';
  public static UP_ERROR = 'error';
  public static UP_LAND = 'land';
  public static UP_COMPANY = 'company';
  public static UP_CONTACT = 'contact';
  public static UP_PRODUCT = 'product';
  public static UP_PARTNERS = 'partners';
  public static UP_ABOUT = 'about';
  public static UP_CASE_STUDY = 'case-study';
  public static UP_NEWS = 'news';
  public static UP_NEWS_AND_EVENTS = 'news-and-events';
  public static UP_SERVICE = 'service';
  public static UP_CAREER = 'career';
  public static UP_CONTACT_US = 'contact-us';
  public static UP_COMPANY_PAGE = 'company-page';
  public static UP_PRIVACY = 'privacy-policy';
  public static UP_SITEMAP = 'sitemap';
  public static UP_PARTNERS_PAGE = 'partners-page';
  public static UP_PARTNER_WITH_US = 'partner-with-us';
  public static UP_BECOME_PARTNER = 'become-partner';
  public static UP_COOKIE = 'cookie-policy';
  public static UP_TOU = 'terms-of-use';
  public static UP_COPYRIGHT = 'copyright';
  public static UP_WHY_XFAC3 = 'why-xfac3';

  // Configuration
  public static RP_COMPANY = '/company';
  public static RP_CONTACT = '/contact';
  public static RP_PRODUCT = '/product/product';
  public static RP_PARTNERS = '/partners';
  public static RP_ABOUT = '/company/about';
  public static RP_CASE_STUDY = '/case-study/case-study';
  public static RP_SERVICE = '/service/service';
  public static RP_NEWS_AND_EVENTS = '/news/news-and-events';
  public static RP_CAREER = '/company/career';
  public static RP_CONTACT_US = '/contact/contact-us';
  public static RP_SITEMAP = '/company/sitemap';
  public static RP_COMAPNY_PAGE = '/company/company-page';
  public static RP_PRIVACY = '/company/privacy-policy';
  public static RP_PARTNERS_PAGE = '/partners/partners-page';
  public static RP_PARTNER_WITH_US = '/partners/partner-with-us';
  public static RP_BECOME_PARTNER = '/partners/become-partner';
  public static RP_COOKIE = '/company/cookie-policy';
  public static RP_TOU = '/company/terms-of-use';
  public static RP_COPYRIGHT = '/company/copyright';
  public static RP_WHY_XFAC3 = '/company/why-xfac3';
  /* URL Path */

  /* Route Path */
  public static RP_LOGIN = '/login';
  public static RP_HOME = '/home';
  /* Route Path */

  /* Title Name */
  public static TN_HOME = 'Home';
  /* Title Name */

  /* Configuration */
  public static CFG_SNKBR_DUR = 3000;
  public static CFG_SNKBR_DUR_COPY = 300;
  public static CFG_COOKIE_EXPIRE = 3;
  public static CFG_CARD_RAISED = 'mat-elevation-z8';
  public static CFG_INPUT_GAP = '40px';
  public static CFG_SIDENAV_TEXT = 250;
  public static CFG_SIDENAV_ICON = 40;
  public static CFG_NAME_CONF = 'Configuration';
  public static CFG_NAME_TRANS = 'Transaction';
  public static CFG_NAME_ENQ = 'Enquiry';
  public static CFG_NAME_REPORT = 'Report';
  public static CFG_NAME_SCORE = 'Scoreboard';
  public static CFG_NAME_LABEL = 'Label';
  public static CFG_SCORE_PER_ROW = 10;
  public static CFG_COOKIE_PATH = '/';
  public static CFG_INPUT_GAP_SMALL = '30px';
  public static CFG_FORM_FIELD_APPEARANCE = 'outline';
  public static CFG_AG_GRID_SELECTION_SINGLE = 'single';
  public static CFG_AG_GRID_SELECTION_MULTI = 'multiple';
  public static CFG_AG_GRID_COL_DEF = {
    resizable: true,
    filter: true,
    sortable: true,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true
  };
  public static CFG_ACTION_NEW = 0;
  public static CFG_ACTION_EDIT = 1;
  public static CFG_ACTION_COPY = 2;
  public static CFG_STYLE_DETAIL_OPTION_ADD = 1;
  public static CFG_STYLE_DETAIL_OPTION_EDIT = 2;
  public static CFG_STYLE_LIST_OPTION_DEFAULT = 1;
  public static CFG_STYLE_LIST_OPTION_SAVE = 2;
  public static CFG_STYLE_LIST_OPTION_NONE = 5;
  public static CFG_STYLE_LIST_OPTION_PRINT = 6;
  public static CFG_STYLE_LIST_OPTION_SERVICE = 7;
  public static CFG_GUID_NONE = 0;
  public static CFG_AG_GRID_CSS_DEFAULT = 1;
  public static CFG_AG_GRID_CSS_SEARCH = 2;
  public static CFG_AG_GRID_CSS_ENQUIRY = 3;
  public static CFG_AG_GRID_CSS_ENQUIRY_REPORT = 4;
  public static CFG_DIALOG_ALERT = 1;
  public static CFG_DIALOG_INFO = 2;
  public static CFG_AG_GRID_CSS_TRANSACTION = 5;
  public static CFG_AG_GRID_CSS_TRANSACTION_ACTION = 6;
  public static CFG_AG_GRID_CSS_SETUP = 7;
  public static CFG_AG_GRID_CSS_DIALOG = 8;
  public static CFG_AG_GRID_CSS_CONSOLE_LIST = 9;
  public static CFG_SSRS_CSS_DEFAULT = 1;
  public static CFG_DASHBOARD_FONT_SIZE = 10;
  public static CFG_DASHBOARD_LABEL_FONT_SIZE = 12;
  public static CFG_DIALOG_HEIGHT = '480px';
  public static CFG_DIVIDER_GAP = '10px';
  public static CFG_START_SCROLL_HEIGHT = 50;
  public static CFG_DASHBOARD_TOOLTIP_FONT_SIZE = 10;
  /* Configuration */

  /* Ag Grid Header Width */
  public static HW_ID = 170;
  public static HW_SETUP_NAME = 180;
  public static HW_NAME = 180;
  public static HW_DESC = 400;
  public static HW_CATEGORY = 120;
  public static HW_SUBCATEGORY = 130;
  public static HW_REMARK = 200;
  public static HW_STATUS = 100;
  public static HW_DATE = 170;
  public static HW_LONG_TEXT = 250;
  public static HW_MEDIUM_TEXT = 170;
  public static HW_SHORT_TEXT = 120;
  public static HW_URL = 210;
  public static HW_60 = 60;
  public static HW_65 = 65;
  public static HW_70 = 70;
  public static HW_80 = 80;
  public static HW_90 = 90;
  public static HW_75 = 75;
  public static HW_85 = 85;
  public static HW_95 = 95;
  public static HW_100 = 100;
  public static HW_110 = 110;
  public static HW_120 = 120;
  public static HW_130 = 130;
  public static HW_140 = 140;
  public static HW_150 = 150;
  public static HW_160 = 160;
  public static HW_170 = 170;
  public static HW_180 = 180;
  public static HW_190 = 190;
  public static HW_200 = 200;
  public static HW_500 = 500;
  /* Ag Grid Header Width */

  /* GptData Table Name */
  public static GPT_APP_TYPE = 'AppType';
  public static GPT_LANGUAGE = 'Language';
  public static GPT_USER_ROLE = 'UserRole';
  public static GPT_GENDER = 'Gender';
  public static GPT_MO_TYPE = 'MoType';
  public static GPT_MO_STATUS = 'MoStatus';
  public static GPT_RECEIPT_TYPE = 'ReceiptType';
  public static GPT_REWORK_ACTION = 'ReworkAction';
  public static GPT_SHIP_DEST = 'ShipDestination';
  public static GPT_USER_TITLE = 'UserTitle';
  public static GPT_DEPARTMENT = 'Department';
  public static GPT_ITEM_FAMILY = 'ItemFamily';
  public static GPT_ITEM_CATEGORY = 'ItemCategory';
  public static GPT_ITEM_TYPE = 'ItemType';
  public static GPT_ITEM_GROUP = 'ItemGroup';
  public static GPT_ITEM_SUB_FAMILY = 'ItemSubFamily';
  public static GPT_ITEM_SUB_CATEGORY = 'ItemSubCategory';
  public static GPT_ITEM_SUB_TYPE = 'ItemSubType';
  public static GPT_ITEM_SUB_GROUP = 'ItemSubGroup';
  public static GPT_ITEM_STATUS = 'ItemStatus';
  public static GPT_UOM = 'UnitOfMeasurement';
  public static GPT_FUNCTION_CATEGORY = 'FunctionCategory';
  public static GPT_FUNCTION_SUB_CATEGORY = 'FunctionSubCategory';
  public static GPT_ACCOUNT_CATEGORY = 'AccountCategory';
  public static GPT_ACCOUNT_SUB_CATEGORY = 'AccountSubCategory';
  /* GptData Table Name */

  /* Common Text */
  public static CT_EMPTY = '';
  public static CT_SPACE = ' ';
  public static CT_SPACE_10 = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  public static CT_PIPE = '\xa0\xa0|\xa0\xa0';
  public static CT_PIPE_SEPERATOR = `  |  `;
  public static CT_SEPERATOR = '\xa0\xa0\xa0|\xa0\xa0\xa0';
  public static CT_ERROR_SEPERATOR = '@';
  public static CT_NUM_0 = 0;
  public static CT_STR_0 = '0';
  public static CT_STR_DOUBLE_0 = '00';
  public static CT_NUM_1 = 1;
  public static CT_STR_1 = '1';
  public static CT_NUM_2 = 2;
  public static CT_STR_2 = '2';
  public static CT_NUM_3 = 3;
  public static CT_STR_3 = '3';
  public static CT_NUM_4 = 4;
  public static CT_STR_4 = '4';
  public static CT_NUM_5 = 5;
  public static CT_STR_5 = '5';
  public static CT_NUM_6 = 6;
  public static CT_NUM_7 = 7;
  public static CT_NUM_8 = 8;
  public static CT_NUM_9 = 9;
  public static CT_NUM_10 = 10;
  public static CT_NUM_11 = 11;
  public static CT_NUM_12 = 12;
  public static CT_NUM_100 = 100;
  public static CT_NUM_500 = 500;
  public static CT_NUM_1000 = 1000;
  public static CT_NUM_13 = 13;
  public static CT_NUM_37 = 37;
  public static CT_NUM_15 = 15;
  public static CT_COLON = ' : ';
  public static CT_COLON_NO_SPACE = ':';
  public static CT_COMMA = ',';
  public static CT_SLAPSH = '/';
  public static CT_ASTRIK = '*';
  public static CT_HASH = '#';
  public static CT_QUESTION_MARK = '?';
  public static CT_DOUBLE_ASTRIK = '**';
  public static CT_DOUBLE_CARROT = '^^';
  public static CT_PIXEL = 'px';
  public static CT_UNDEFINED = undefined;
  public static CT_NULL = null;
  public static CT_NULL_TEXT = 'null';
  public static CT_OK = 'OK';
  public static CT_NG = 'NG';
  public static CT_PASS = 'PASS';
  public static CT_FAIL = 'FAIL';
  public static CT_RETEST = 'RETEST';
  public static CT_ALERT = 'Alert';
  public static CT_YES = 'YES';
  public static CT_NO = 'NO';
  public static CT_YES_TEXT = 'Yes';
  public static CT_NO_TEXT = 'No';
  public static CT_PERCENTAGE_100 = '100%';
  public static CT_PERCENTAGE_50 = '50%';
  public static CT_SELECT_RECORD = 'SelectRecord';
  public static CT_SELECT_1_RECORD = 'Only can select 1 record';
  public static CT_DISCARD_RECORD = 'DiscardRecord';
  public static CT_DELETE_RECORD = 'DeleteRecord';
  public static CT_RESET_PASSWORD = 'ResetPassword';
  public static CT_TRUE = true;
  public static CT_TRUE_TEXT = 'TRUE';
  public static CT_FALSE = false;
  public static CT_ITEM_DETAILS = 'Item Details';
  public static CT_PCB_DETAILS = 'PCB Details';
  public static CT_INSPECT_DETAILS = 'Inspection Details';
  public static CT_MATERIAL_DETAILS = 'Material Details';
  public static CT_MULTIPLE = 'multiple';
  public static CT_SINGLE = 'single';
  public static CT_BARCODE_FIELD = 'Tracking ID';
  public static CT_WORKORDER_NOT_MATCH = 'Error! Work Order is not match.';
  public static CT_PCB_REPEAT = 'PCB sn is repeated';
  public static CT_INVALID_BARCODE = 'Invalid Tracking ID';
  public static CT_INVALID_DEFECT_BARCODE = 'Invalid Defect Code';
  public static CT_SCAN_BARCODE = 'Scan Tracking ID';
  public static CT_COLUMN = 'column';
  public static CT_ROW = 'row';
  public static CT_OPEN = 'Open';
  public static CT_SCHEDULED = 'Scheduled';
  public static CT_WIP = 'Work In Progress';
  public static CT_CLOSED = 'Closed';
  public static CT_CANCELLED = 'Cancelled';
  public static CT_AWAITING = 'Awaiting';
  public static CT_ONHOLD = 'OnHold';
  public static CT_STARTED = 'Started';
  public static CT_COMAPNY_NAME = 'SKP BM Electronics Sdn. Bhd. (1225050-X)';
  public static CT_ALL = 'ALL';
  public static CT_NONE = 'NONE';
  public static CT_ADD = 'Add';
  public static CT_EDIT = 'Edit';
  public static CT_PREFIX_EQUIP = '(ST)';
  public static CT_PREFIX_MATERIAL = '(RM)';
  public static CT_PREFIX_RESET = '(RS)';
  public static CT_SPLITTER = ',';
  public static CT_DATE_FORMAT_YYWW = 'YYWW';
  public static CT_DATE_FORMAT_YYMMDD = 'YYMMDD';
  public static CT_DATEPIPE_FORMAT_YYYYMMDD = 'yyyy-MM-dd';
  public static CT_DATEPIPE_FORMAT_YYMMDD = 'yyMdd';
  public static CT_DATEPIPE_FORMAT_DATECODE = 'yyyyMMdd';
  public static CT_DATEPIPE_FORMAT_FULL = 'yyyy-MM-dd h:mm:ss a';
  public static CT_DATEPIPE_FORMAT_FULL_2 = 'yyyyMMddHHmm';
  public static CT_DATEPIPE_FORMAT_FULL_DB = 'yyyy-MM-dd h:mm:ss a';
  public static CT_NORMAL = 'Normal';
  public static CT_PO = 'Purchase Order';
  public static CT_TO = 'Transfer Order';
  public static CT_MO = 'Manual';
  public static CT_KEY_FIELD_NAME = 'Key Field Name';
  public static CT_VALUE_FIELD_NAME = 'Value Field Name';
  public static CT_ENTER = 'Enter';
  public static CT_ENGLISH = 'ENG';
  public static CT_PCBA = 'PCBA';
  public static CT_RM = 'RM';
  public static CT_INACTIVE_OPTION_ALERT = 'Selected Option Is Inactive Status. Do You Still Want To Continue ?';
  public static CT_DASH = '-';
  public static CT_PREFIX_CARTON = '(CB)';
  public static CT_IP_PATTERN =
    '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
  public static CT_EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  // Return Message
  public static CT_TRANSACT_SUCCESS = 'Transacted successfully.';
  public static CT_RECEIVE_ORDER = 'ReceiveOrder';
  public static CT_TRANSFER_ORDER = 'TransferOrder';
  public static CT_AVAILABLE = 'AVAILABLE';
  public static CT_START = 'START';
  public static CT_END = 'END';
  /* Common Text */
}

import { AppConstant } from '@app/app.constant';

export const companyMenuList = [
    {
        category: 'Company',
        title: 'About us',
        desc: 'Behind xFac3',
        imgUrl: '/assets/img/frame/submenu/blank.png',
        nav: AppConstant.RP_HOME + AppConstant.RP_ABOUT
    },
    {
        category: 'Company',
        title: 'Why xFac3',
        desc: 'Learn more about xFac3 to see how we helped our clients',
        imgUrl: '/assets/img/frame/submenu/blank.png',
        nav: AppConstant.RP_HOME + AppConstant.RP_WHY_XFAC3
    },
    {
        category: 'Company',
        title: 'Careers',
        desc: 'Step in to explore the wealth of career opportunities and take your career to the next level',
        imgUrl: '/assets/img/frame/submenu/blank.png',
        nav: AppConstant.RP_HOME + AppConstant.RP_CAREER
    }
];

export const solutiontMenuList = [
    {
        category: 'Solutions',
        title: 'Production Shopfloors',
        desc:
            'Computerized systems used in manufacturing, to track and document the transformation of raw materials to finished goods',
        imgUrl: '/assets/img/frame/submenu/mes_submenu.png',
        nav: ''// AppConstant.RP_HOME + AppConstant.RP_PRODUCT
    },
    {
        category: 'Solutions',
        title: 'Warehouses',
        desc: 'Designed to support and optimize warehouse functionality and distribution center management',
        imgUrl: '/assets/img/frame/submenu/wms_submenu.jpg',
        nav: ''// AppConstant.RP_HOME + AppConstant.RP_PRODUCT
    },
    {
        category: 'Solutions',
        title: 'Maintenances',
        desc:
            'Support management of the maintenance of physical assets of an organization throughout each asset lifecycle' +
            AppConstant.CT_SPACE_10,
        imgUrl: '/assets/img/frame/submenu/mms_submenu.jpg',
        nav: ''// AppConstant.RP_HOME + AppConstant.RP_PRODUCT
    },
    {
        category: 'Solutions',
        title: 'Equipment integrations',
        desc: 'Full turnkey engineering solutions that improve output, quality, and reliability for any manufacturing environment by continuously collecting data and providing two-way communication between production machines and the host you are using.',
        imgUrl: '/assets/img/frame/submenu/oe_submenu.png',
        nav: ''// AppConstant.RP_HOME + AppConstant.RP_PRODUCT
    },
    {
        category: 'Solutions',
        title: 'Automation',
        desc:
            'The use of control systems, such as computers, controllers or robots, and information technologies for handling different processes and machineries in an industry to replace a human being.',
        imgUrl: '/assets/img/frame/submenu/automation_submenu.png',
        nav: ''// AppConstant.RP_HOME + AppConstant.RP_PRODUCT
    },
    {
        category: 'Solutions',
        title: 'Business Reporting',
        desc: 'An on-premises reporting environment to create and share reports behind your firewall with data separation and isolation. Reporting solution offering a designer, viewer, writer, and server embedded within a web application.',
        imgUrl: '/assets/img/frame/submenu/br_submenu.png',
        nav: ''// AppConstant.RP_HOME + AppConstant.RP_PRODUCT
    }
];

export const mainNavList = [
    {
        category: 'HOME',
        nav: [AppConstant.RP_HOME],
        isSubMenu: false
    },
    {
        category: 'COMPANY',
        nav: [
            AppConstant.RP_HOME + AppConstant.RP_ABOUT,
            AppConstant.RP_HOME + AppConstant.RP_CAREER,
            AppConstant.RP_HOME + AppConstant.RP_SITEMAP
        ],
        subMenu: companyMenuList,
        isSubMenu: true
    },
    {
        category: 'SOLUTIONS',
        nav: '', // [AppConstant.RP_HOME + AppConstant.RP_PRODUCT],
        subMenu: solutiontMenuList,
        isSubMenu: true
    },
    // {
    //     category: 'SERVICES',
    //     nav: '', // [AppConstant.RP_HOME + AppConstant.RP_SERVICE],
    //     isSubMenu: false
    // },
    {
        category: 'PARTNERS',
        nav: [
            AppConstant.RP_HOME + AppConstant.RP_PARTNERS_PAGE,
            AppConstant.RP_HOME + AppConstant.RP_PARTNER_WITH_US,
            AppConstant.RP_HOME + AppConstant.RP_BECOME_PARTNER
        ],
        isSubMenu: false
    },
    {
        category: 'NEWS & EVENTS',
        nav: '', // [AppConstant.RP_HOME + AppConstant.RP_NEWS_AND_EVENTS],
        isSubMenu: false
    },
    {
        category: 'CONTACT',
        nav: [AppConstant.RP_HOME + AppConstant.RP_CONTACT_US],
        isSubMenu: false
    }
];



import { Directive, HostListener, Input } from '@angular/core';
import { AppConstant } from '@app/app.constant';

@Directive({
  selector: '[appNumeric]'
})
export class NumericDirective {
  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const charCode = this.getCharCodeFromEvent(e);
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(charCode) !== -1 ||
      (charCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (charCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (charCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (charCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (charCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (charCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (charCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (charCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (charCode >= 35 && charCode <= 39) // Home, End, Left, Right
    ) {
      return; // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
      e.preventDefault();
    }
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return typeof event.which === undefined ? event.keyCode : event.which;
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstant } from '@app/app.constant';
import { ICareerApply, ICareer } from '@app/_models/common.model';
import { DataService } from '@app-helpers/data.service';
import { SubSink } from 'subsink';
import { ErrorDialogService } from '@app/_shared/_services/error-dialog.service';
import { errorMessages } from '@app/_helpers/error-message';
import { CommonService } from '@app/_services/common.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-request-demo-dialog',
  templateUrl: './request-demo-dialog.component.html',
  styleUrls: ['./request-demo-dialog.component.scss']
})
export class RequestDemoDialogComponent implements OnInit, OnDestroy {
  dialogCreateform: FormGroup;
  inputGap = '5px';
  appearance = AppConstant.CFG_FORM_FIELD_APPEARANCE;
  loading = AppConstant.CT_FALSE;
  reqCount = AppConstant.CT_NUM_0;
  errors = errorMessages;
  emptyFunction = Function();
  AppConstant = AppConstant;
  fieldControl: {
    [key: string]: AbstractControl;
  };
  dialogType: string;
  dialogData = [];
  dialogTitle = AppConstant.CT_EMPTY;
  subscription = new SubSink();
  todayDate = new Date();
  selectedFiles: any;
  selectedFile: File = null;
  fba: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  fileDownloadLink: string;
  filePath: string;
  file: any;
  maxAllowedSize = 2;
  isNotAllowedSize = false;
  isValidCaptcha = false;
  firstLoad = true;
  findMethodList = ['Referral', 'Online search', 'Social media', 'Others'];
  solutionList = [
    'Production shopfloors',
    'Warehouses',
    'Maintenances',
    'Equipment integrations',
    'Business reportings'
  ];
  constructor(
    public dialogRef: MatDialogRef<RequestDemoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICareer,
    private fb: FormBuilder,
    private errorService: ErrorDialogService,
    private dataService: DataService,
    private commonService: CommonService,
    private storage: AngularFireStorage
  ) {
    this.formLoad();
  }

  ngOnInit() {
    this.dialogRef.disableClose = false;
    this.dialogRef.backdropClick().subscribe((res) => {
      this.onClose();
    });
  }

  public formLoad(): void {
    console.log(this.data);
    this.dialogCreateform = this.fb.group({
      fullName: [AppConstant.CT_EMPTY, Validators.required],
      contactNumber: [AppConstant.CT_EMPTY, Validators.required],
      email: [AppConstant.CT_EMPTY, [Validators.required, Validators.pattern(AppConstant.CT_EMAIL_PATTERN)]],
      tellUsMore: [AppConstant.CT_EMPTY],
      organisation: [AppConstant.CT_EMPTY, Validators.required],
      recaptchaReactive: [null, Validators.required],
      find_method: [AppConstant.CT_EMPTY],
      solutions: [AppConstant.CT_EMPTY, Validators.required]
    });
    this.fieldControl = this.dialogCreateform.controls;
    this.listingInit();
  }

  public listingInit(): void {}

  public onSave(): void {
    this.dialogCreateform.valid ? this.onSaveData() : this.onValidateForm();
  }

  public onSaveData(): void {
    this.dialogRef.close();
    this.showLoading();
    const completeData = this.prepareCreateData();
    this.commonService.sendRequestDemo(completeData as any).then(
      (res) => {
        const data = { result: true, docId: res.id };
        this.dialogRef.close(data);
        this.hideLoading();
      },
      (error) => {
        this.errorService.promptAlertMessage(error);
        this.hideLoading();
      }
    );
  }

  public prepareCreateData(): any {
    const dataList: any = {
      fullName: this.fieldControl['fullName'].value,
      contactNumber: this.fieldControl['contactNumber'].value,
      email: this.fieldControl['email'].value,
      tellUsMore: this.fieldControl['tellUsMore'].value,
      organisation: this.fieldControl['organisation'].value,
      find_method: this.fieldControl['find_method'].value,
      solutions: this.fieldControl['solutions'].value,
      applyDateTime: this.dataService.convertDateTime(this.todayDate)
    };
    return dataList;
  }

  public onValidateForm(): void {
    Object.keys(this.dialogCreateform.controls).forEach((field) => {
      const control = this.dialogCreateform.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: AppConstant.CT_TRUE });
      }
    });

    if (this.fieldControl['recaptchaReactive'].invalid) {
      this.isValidCaptcha = false;
    } else {
      this.isValidCaptcha = true;
    }
    this.firstLoad = false;
  }

  public resolved(captchaResponse: string): void {
    if (captchaResponse !== null) {
      this.isValidCaptcha = true;
      this.firstLoad = false;
    } else {
      this.isValidCaptcha = false;
    }
  }

  public onClose(): void {
    if (this.dialogCreateform.dirty) {
      if (window.confirm('Are you want to discard without submit?')) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  public showLoading(): void {
    this.reqCount++;
    this.loading = AppConstant.CT_TRUE;
  }

  public hideLoading(): void {
    if (this.reqCount > AppConstant.CT_NUM_0) {
      this.reqCount--;
    }
    if (this.reqCount === AppConstant.CT_NUM_0) {
      this.loading = AppConstant.CT_FALSE;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@app-helpers/data.service';
import { AppConstant } from '@app/app.constant';

@Pipe({
  name: 'tooltip',
  pure: false
})
export class TooltipPipe implements PipeTransform {
  constructor(private dataService: DataService) {}

  transform(key: any, type: string): any {
    return this.dataService.checkStringValid(key) ? key[type] : AppConstant.CT_EMPTY;
  }
}

import { Routes } from '@angular/router';
import { AuthGuard } from '@app-guards/auth.guard';
import { ErrorComponent } from '@app-root/error/error.component';
import { PageNotFoundComponent } from '@app-root/page-not-found/page-not-found.component';
import { AppConstant } from '@app/app.constant';
import { VcardComponent } from './vcard/vcard.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: AppConstant.UP_HOME,
    pathMatch: 'full'
  },
  {
    path: AppConstant.UP_HOME,
    loadChildren: './home/home.module#HomeModule',
    canLoad: [AuthGuard],
    data: { preload: true }
  },
  {
    path: AppConstant.UP_ERROR,
    component: ErrorComponent
  },
  {
    path: 'vcard/:username',
    component: VcardComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

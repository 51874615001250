import { Component, OnInit, HostListener, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConstant } from '@app/app.constant';
import { RequestDemoDialogComponent } from '../request-demo-dialog/request-demo-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  innerWidth: any;
  carrerRouteLink = AppConstant.RP_HOME + AppConstant.RP_CAREER;
  contactRouterLink = AppConstant.RP_HOME + AppConstant.RP_CONTACT_US;
  privacyRouterLink = AppConstant.RP_HOME + AppConstant.RP_PRIVACY;
  siteMapLink = AppConstant.RP_HOME + AppConstant.RP_SITEMAP;
  partnerRouteLink = AppConstant.RP_HOME + AppConstant.RP_PARTNERS_PAGE;
  customerPortalUrl = 'https://cp.etronix.asia/';
  cookieRouterLink = AppConstant.RP_HOME + AppConstant.RP_COOKIE;
  touRouterLink = AppConstant.RP_HOME + AppConstant.RP_TOU;
  copyrightRouterLink = AppConstant.RP_HOME + AppConstant.RP_COPYRIGHT;
  twitterUrl = 'https://twitter.com/etronix_sdn_bhd';
  facebookUrl = 'https://www.facebook.com/Etronix-103021662199974';
  instagramUrl = 'https://www.instagram.com/etronix.sdn.bhd/';
  linkedinUrl = 'https://www.linkedin.com/company/etronix-sdn-bhd';
  currentYear = new Date().getFullYear();
  newsRouterLink = AppConstant.RP_HOME + AppConstant.RP_NEWS_AND_EVENTS;
  eventsRouterLink = AppConstant.RP_HOME + AppConstant.RP_NEWS_AND_EVENTS;
  aboutRouterLink = AppConstant.RP_HOME + AppConstant.RP_ABOUT;
  solutionRouterLink = AppConstant.RP_HOME + AppConstant.RP_PRODUCT;
  whyxFac3RouterLink = AppConstant.RP_HOME + AppConstant.RP_WHY_XFAC3;
  caseStudiesRouterLink = AppConstant.RP_HOME + AppConstant.RP_CASE_STUDY;
  partnerPortalLink = 'https://pp.etronix.asia/';

  dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  public redirectToRequestDemo(): void {
    this.dialogRef = this.dialog.open(RequestDemoDialogComponent, {
      data: '',
      width: '60%',
      height: '660px',
      autoFocus: AppConstant.CT_FALSE,
      restoreFocus: AppConstant.CT_FALSE
    });
  }
}

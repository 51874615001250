import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstant } from '@app/app.constant';
import { INewsEvents } from '@app/_models/common.model';
import { SubSink } from 'subsink';
import { errorMessages } from '@app/_helpers/error-message';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-news-events-dialog',
  templateUrl: './news-events-dialog.component.html',
  styleUrls: ['./news-events-dialog.component.scss']
})
export class NewsEventsDialogComponent implements OnInit, OnDestroy {
  inputGap = '5px';
  appearance = AppConstant.CFG_FORM_FIELD_APPEARANCE;
  loading = AppConstant.CT_FALSE;
  reqCount = AppConstant.CT_NUM_0;
  errors = errorMessages;
  emptyFunction = Function();
  AppConstant = AppConstant;
  dialogType: string;
  dialogData = [];
  dialogTitle = AppConstant.CT_EMPTY;
  subscription = new SubSink();

  constructor(
    public dialogRef: MatDialogRef<NewsEventsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INewsEvents,
  ) {
    this.dialogRef.disableClose = false;
  }

  ngOnInit() {}

  public onClose(): void {
    this.dialogRef.close();
  }

  public showLoading(): void {
    this.reqCount++;
    this.loading = AppConstant.CT_TRUE;
  }

  public hideLoading(): void {
    if (this.reqCount > AppConstant.CT_NUM_0) {
      this.reqCount--;
    }
    if (this.reqCount === AppConstant.CT_NUM_0) {
      this.loading = AppConstant.CT_FALSE;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

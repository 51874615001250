import { Component, OnInit } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { DataService } from '@app-helpers/data.service';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {
  public value: any;
  statusStyle = AppConstant.CT_EMPTY;
  constructor(private dataService: DataService) {}

  ngOnInit(): void {}

  agInit(params: any): void {
    // console.log(params);
    this.value = params.value;
    if (this.dataService.checkStringValid(params.data)) {
      this.changesStatusStyle(params);
    } else {
      return AppConstant.CT_NULL;
    }
  }

  public changesStatusStyle(params: any): void {
    const condition = params.data.Status;
    switch (condition) {
      case 'Running':
        this.statusStyle = 'status run';
        break;
      case 'Error':
        this.statusStyle = 'status error';
        break;
      case 'Stop':
        this.statusStyle = 'status stop';
        break;
      default:
        this.statusStyle = 'status undefined';
        break;
    }
  }
}

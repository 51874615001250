import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@app-guards/auth.guard';
import { RequestInterceptor } from '@app-guards/request.interceptor';
import { ResponseInterceptor } from '@app-guards/response.interceptor';
import { DataService } from '@app-helpers/data.service';
import { DialogComponent } from '@app-helpers/dialog/dialog.component';
import { ErrorComponent } from '@app-root/error/error.component';
import { PageNotFoundComponent } from '@app-root/page-not-found/page-not-found.component';
import { HandlerService } from '@app-services/handler.service';
import { TranslateService } from '@app-services/translate.service';
import { ErrorDialogService } from '@app/_shared/_services/error-dialog.service';
import { SharedModule } from '@app-shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { environment } from '@app-env/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { VcardComponent } from './vcard/vcard.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    DialogComponent,
    ErrorComponent,
    VcardComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    SharedModule,
    AngularFontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule
  ],
  entryComponents: [DialogComponent],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    TranslateService,
    HandlerService,
    ErrorDialogService,
    DataService,
    DatePipe,
    { provide: BUCKET, useValue: environment.firebaseConfig.storageBucket }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}

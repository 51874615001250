import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-attribute-standard',
  templateUrl: './attribute-standard.component.html',
  styleUrls: ['./attribute-standard.component.scss']
})
export class AttributeStandardComponent implements OnInit {
  displayedAttrColumns = ['attr', 'value'];
  tableData = [];
  @Input() getTableData: any;
  constructor() {
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-under-maintenance',
  templateUrl: './page-under-maintenance.component.html',
  styleUrls: ['./page-under-maintenance.component.scss']
})
export class PageUnderMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.scrollTo('#top');
  }

  public scrollTo(where): void {
    const scrollElem = document.querySelector(where);
    scrollElem.scrollIntoView();
  }

}

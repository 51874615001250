import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { companyMenuList, solutiontMenuList } from '../header.nav';

@Component({
  selector: 'app-header-carousel',
  templateUrl: './header-carousel.component.html',
  styleUrls: ['./header-carousel.component.scss']
})
export class HeaderCarouselComponent implements OnInit, OnChanges {
  featureList = [];
  bulletList = [];
  AppConstant = AppConstant;
  emptyFunction = Function();
  @Input() categoryHover: string;
  @Output() isTurnDown: EventEmitter<any> = new EventEmitter();
  @Output() selectedSubMenu: EventEmitter<any> = new EventEmitter();
  turnDown = true;
  btnCount = 1;
  carouselRightId = 'carousel-slide-activator-2';
  carouselLeftId = 'carousel-slide-activator-2';

  constructor(private router: Router) { }

  ngOnInit() { }

  // to detect when mouse hover on different menu category and fast rebuild the menu without time delag
  ngOnChanges(categoryHoverChanges: any) {
    this.buildSubMenu();
    this.buildCarousellBullet();
  }

  public buildSubMenu(): void {
    this.featureList = [];

    if (this.categoryHover === 'COMPANY') {
      this.featureList = companyMenuList;
    } else if (this.categoryHover === 'SOLUTIONS') {
      this.featureList = solutiontMenuList;
    } else {
      this.featureList = [];
    }
  }

  public buildCarousellBullet(): void {
    const navTotal = this.featureList.length;
    const bulletDisplay = Math.ceil(navTotal / 3);
    this.bulletList = [];
    if (this.featureList.length > 3) {
      for (let i = 1; i <= bulletDisplay; i++) {
        this.bulletList.push({
          id: i,
          class: 'dot'
        });
      }
    } else {
      this.bulletList = [];
    }
  }

  public addCount(): void {
    if (this.btnCount === this.bulletList.length) {
      this.carouselRightId = 'carousel-slide-activator-1';
      this.btnCount = 1;
    } else {
      this.btnCount += 1;
      this.carouselRightId = 'carousel-slide-activator-' + this.btnCount;
    }
  }

  public minusCount(): void {
    this.btnCount > 0 ? (this.btnCount -= 1) : (this.btnCount = this.btnCount);
    if (this.btnCount === 0) {
      this.carouselLeftId = 'carousel-slide-activator-' + this.bulletList.length;
      this.btnCount = this.bulletList.length;
    } else {
      this.carouselLeftId = 'carousel-slide-activator-' + this.btnCount;
    }
  }

  sendisTurnDown() {
    this.isTurnDown.emit(AppConstant.CT_FALSE);
  }

  sendSubMenu(val: string) {
    if (val) {
      this.selectedSubMenu.emit(val.toUpperCase());
    }
  }
}

import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-numeric-editor',
  templateUrl: './numeric-editor.component.html',
  styleUrls: ['./numeric-editor.component.scss']
})
export class NumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: string;
  private cancelBeforeStart = false;

  @ViewChild('input', { static: true, read: ViewContainerRef }) public input;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart = params.charPress && '1234567890'.indexOf(params.charPress) < 0;
  }

  getValue(): any {
    const _value =
      Number(this.value) === AppConstant.CT_NUM_0 && this.value === AppConstant.CT_EMPTY
        ? AppConstant.CT_EMPTY
        : this.value;
    return _value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return Number(this.value) > 1000000;
  }

  onKeyDown(event): void {
    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  private getCharCodeFromEvent(event): any {
    // tslint:disable-next-line: deprecation
    event = event || window.event;
    return typeof event.which === undefined ? event.keyCode : event.which;
  }

  private isKeyPressedNumeric(event): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode !== 190 &&
      (charCode < 37 || charCode > 40) &&
      charCode !== 46
    ) {
      return false;
    } else {
      return true;
    }
  }
}

import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {LicenseManager} from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('Evaluation_License_Not_For_Production_17_December_2019__MTU3NjU0MDgwMDAwMA==579adc0faf540764c592ca89db91dfd2');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { providers: [{ provide: LOCALE_ID, useValue: navigator.language }] })
  .catch(err => console.log(err));

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstant } from '@app/app.constant';
import { ICareerApply, ICareer } from '@app/_models/common.model';
import { DataService } from '@app-helpers/data.service';
import { SubSink } from 'subsink';
import { ErrorDialogService } from '@app/_shared/_services/error-dialog.service';
import { errorMessages } from '@app/_helpers/error-message';
import { CommonService } from '@app/_services/common.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-career-dialog',
  templateUrl: './career-dialog.component.html',
  styleUrls: ['./career-dialog.component.scss']
})
export class CareerDialogComponent implements OnInit, OnDestroy {
  dialogCreateform: FormGroup;
  inputGap = '5px';
  appearance = AppConstant.CFG_FORM_FIELD_APPEARANCE;
  loading = AppConstant.CT_FALSE;
  reqCount = AppConstant.CT_NUM_0;
  errors = errorMessages;
  emptyFunction = Function();
  AppConstant = AppConstant;
  fieldControl: {
    [key: string]: AbstractControl;
  };
  dialogType: string;
  dialogData = [];
  dialogTitle = AppConstant.CT_EMPTY;
  subscription = new SubSink();
  todayDate = new Date();
  selectedFiles: any;
  selectedFile: File = null;
  fba: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  fileDownloadLink: string;
  filePath: string;
  file: any;
  maxAllowedSize = 2;
  isNotAllowedSize = false;
  isValidCaptcha = false;
  firstLoad = true;

  constructor(
    public dialogRef: MatDialogRef<CareerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICareer,
    private fb: FormBuilder,
    private errorService: ErrorDialogService,
    private dataService: DataService,
    private commonService: CommonService,
    private storage: AngularFireStorage
  ) {
    this.formLoad();
  }

  ngOnInit() {
    this.dialogRef.disableClose = false;
    this.dialogRef.backdropClick().subscribe((res) => {
      this.onClose();
    });
  }

  public formLoad(): void {
    console.log(this.data);
    this.dialogCreateform = this.fb.group({
      fullName: [AppConstant.CT_EMPTY, Validators.required],
      contactNumber: [AppConstant.CT_EMPTY, Validators.required],
      email: [AppConstant.CT_EMPTY, [Validators.required, Validators.pattern(AppConstant.CT_EMAIL_PATTERN)]],
      tellUsMore: [AppConstant.CT_EMPTY],
      cvFile: [AppConstant.CT_EMPTY, Validators.required],
      fileHolder: [AppConstant.CT_EMPTY],
      recaptchaReactive: [null, Validators.required]
    });
    this.fieldControl = this.dialogCreateform.controls;
    this.listingInit();
  }

  public listingInit(): void {}

  public detectFile(event) {
    this.file = event.target.files[0];
    /*Maximum allowed size in bytes
        5MB Example
        Change first operand(multiplier) for your needs*/
    const checkSize = this.maxAllowedSize * 1024 * 1024;
    const fileName = this.file.name;

    if (event.target.files && this.file && this.file.size < checkSize && this.isValidInputFile(this.file)) {
      this.fieldControl['cvFile'].setValue(fileName);
      const today = this.dataService.convertDateTime(new Date());
      this.filePath = 'JobApply' + '/' + today + '-' + fileName;
    } else {
      // if didnt match allowed size and incorrect format
      this.fieldControl['cvFile'].setValue(AppConstant.CT_EMPTY);
      this.isNotAllowedSize = true;
    }
  }

  public isValidInputFile(file: any) {
    if (file) {
      return file.name.endsWith('.pdf');
    }
  }

  public uploadFileToFireBase() {
    this.showLoading();
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(`${this.filePath}`, this.file);
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe((url) => {
            if (url) {
              this.fileDownloadLink = url;
              this.onSaveData(url);
              this.hideLoading();
            } else {
              this.errorService.promptAlertMessage('Error when upload file. Please try again later.');
              this.hideLoading();
            }
          });
        })
      )
      .subscribe((url) => {
        if (url) {
          // console.log(url);
        }
      });
  }

  public onSave(): void {
    this.dialogCreateform.valid ? this.uploadFileToFireBase() : this.onValidateForm();
  }

  public onSaveData(fileUrl): void {
    this.showLoading();
    const completeData = this.prepareCreateData(fileUrl);
    this.commonService.CreateCareerApply(completeData as ICareerApply).then(
      (res) => {
        const data = { result: true, docId: res.id };
        this.dialogRef.close(data);
        this.hideLoading();
      },
      (error) => {
        this.errorService.promptAlertMessage(error);
        this.hideLoading();
      }
    );
  }

  public prepareCreateData(fileUrl): ICareerApply {
    const dataList: ICareerApply = {
      jobTitleApply: this.data.jobTitle,
      jobIdApply: this.data.id,
      fullName: this.fieldControl['fullName'].value,
      contactNumber: this.fieldControl['contactNumber'].value,
      email: this.fieldControl['email'].value,
      tellUsMore: this.fieldControl['tellUsMore'].value,
      cvFile: fileUrl,
      applyDateTime: this.dataService.convertDateTime(this.todayDate)
    };
    return dataList;
  }

  public onValidateForm(): void {
    Object.keys(this.dialogCreateform.controls).forEach((field) => {
      const control = this.dialogCreateform.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: AppConstant.CT_TRUE });
      }
    });

    if (this.fieldControl['recaptchaReactive'].invalid) {
      this.isValidCaptcha = false;
    } else {
      this.isValidCaptcha = true;
    }
    this.firstLoad = false;
  }

  public resolved(captchaResponse: string): void {
    if (captchaResponse !== null) {
      this.isValidCaptcha = true;
      this.firstLoad = false;
    } else {
      this.isValidCaptcha = false;
    }
  }

  public onClose(): void {
    if (this.dialogCreateform.dirty) {
      if (window.confirm('Are you want to discard without submit?')) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  public showLoading(): void {
    this.reqCount++;
    this.loading = AppConstant.CT_TRUE;
  }

  public hideLoading(): void {
    if (this.reqCount > AppConstant.CT_NUM_0) {
      this.reqCount--;
    }
    if (this.reqCount === AppConstant.CT_NUM_0) {
      this.loading = AppConstant.CT_FALSE;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

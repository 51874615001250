import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { companyMenuList, mainNavList, solutiontMenuList } from './header.nav';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  featureList = [];
  AppConstant = AppConstant;
  turnDown = false;
  showMobileMenu = false;
  timeInternal: any;
  emptyFunction = Function();
  currentHoverMenuCategory = AppConstant.CT_EMPTY;
  showMobileMenu2 = true;
  isActiveRoute: boolean;
  currentActiveCategory = AppConstant.CT_EMPTY;
  urlData = mainNavList;
  isScrollDown = false;
  subMenuList = [];

  constructor(private router: Router) {
    this.buildMenu();
    // set Active Menu on every route change
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.setActiveMenu();
      }
    });
  }

  ngOnInit() {
    // to detect the scroll event
    window.addEventListener('scroll', this.scroll, true);
  }

  scroll = (event: any): void => {
    // detect landing body class 
    const landing = document.querySelector('.landing-body');
    // if detect only execute
    if (landing) {
      if (landing.scrollTop > window.innerHeight / 2) {
        setTimeout(() => {
          this.isScrollDown = true;
        }, 500);

      } else {
        setTimeout(() => {
          this.isScrollDown = false;
        }, 500);
      }
    }
    // if not detect landing page 
    else {
      const number = event.srcElement.scrollTop;
      // extra layer checking to prevent trigger function when scroll within page
      if (event.target.firstChild.nodeName === 'ROUTER-OUTLET') {
        if (number > 50) {
          setTimeout(() => {
            this.isScrollDown = true;
          }, 300);
        } else {
          setTimeout(() => {
            this.isScrollDown = false;
          }, 300);
        }
      }
    }
  };

  public setActiveMenu(): void {
    // to catch the brower URL and set the active menu
    if (this.router.url === AppConstant.RP_HOME) {
      this.currentActiveCategory = 'HOME';
    } else {
      this.currentActiveCategory = this.urlData
        .filter((x) => x.nav.includes(this.router.url))
        .map((y) => y.category)
        .toString();
    }
    // console.log(this.currentActiveCategory);
  }

  public buildMenu(): void {
    this.featureList = this.urlData;
  }

  public expandSubMenu(category, isSubMenu): void {
    clearTimeout(this.timeInternal);
    // create condition to macth the menu title name
    if (isSubMenu) {
      this.currentHoverMenuCategory = category;
      this.setDivFocus();
    } else {
      this.turnDown = false;
    }
  }

  public setTimeOut(): void {
    clearTimeout(this.timeInternal);
    this.timeInternal = setTimeout(() => {
      this.turnDown = false;
    }, 300);
  }

  public setDivFocus(): void {
    this.turnDown = true;
    clearTimeout(this.timeInternal);
  }

  public toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  public onClickedOutside() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  public setDivOutFocus(event): void {
    this.turnDown = event;
    clearTimeout(this.timeInternal);
    this.timeInternal = 0;
  }
  public getSelectedSubMenu(event): void {
    // to get the submenu category from the header-carousel component
    this.currentActiveCategory = event;
  }

  public mobileNavClick(e): void {

    if (e.isSubMenu) {
      this.currentHoverMenuCategory = e.category;

      switch (this.currentHoverMenuCategory) {
        case 'Company':
          this.subMenuList = companyMenuList;
          break;
        case 'Solutions':
          this.subMenuList = solutiontMenuList;
          break;
        default:
          break;
      }
      this.turnDown = !this.turnDown;
    } else {
      this.showMobileMenu = false;
      this.turnDown = false;
    }

  }

  ngOnDestroy() {
    clearTimeout(this.timeInternal);
    window.removeEventListener('scroll', this.scroll, true);
  }
}

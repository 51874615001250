import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from '@app-helpers/data.service';
import { AppConstant } from '@app/app.constant';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  dialogTitle: string;
  dialogContent: string;
  dialogOkLabel: string;
  dialogCloseLabel: string;
  dialogIcon: number;
  errorMessage: string;
  error: any;
  dialogError1 = AppConstant.CT_EMPTY;
  dialogError2 = AppConstant.CT_EMPTY;
  dialogError3 = AppConstant.CT_EMPTY;
  dialogError4 = AppConstant.CT_EMPTY;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.dialogTitle = this.data[0];
    this.dialogContent = this.data[1];
    this.error = this.dialogContent.split(AppConstant.CT_ERROR_SEPERATOR);
    if (this.error.length > 1) {
      this.dialogError1 = this.error[0];
      this.dialogError2 = this.dataService.verifyString(this.error[1]);
      this.dialogError3 = this.dataService.verifyString(this.error[2]);
      this.dialogError4 = this.dataService.verifyString(this.error[3]);
    } else {
      this.dialogError1 = this.dialogContent;
    }
    this.dialogOkLabel = this.data[3].toUpperCase();
    this.dialogCloseLabel = this.data[2].toUpperCase();
    this.dialogIcon = Number(this.data[4]);
  }

  public onPass(id: number) {
    if (id === 1) {
      this.dialogRef.close(AppConstant.CT_TRUE);
    } else {
      this.dialogRef.close(AppConstant.CT_FALSE);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}

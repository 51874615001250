export const errorMessages: { [key: string]: string } = {
  requiredField: 'This field is required',
  qtyGreaterThan0: 'QtyGreaterThan0',
  overEqualQty: 'OverEqualQty',
  ipAddressInvalid: 'IPAddressInvalid',
  portPrinterInvalid: 'PortPrinterInvalid',
  minFileWatcherNetworkInterval: 'Min Interval cannot less than 5000ms',
  minReprocessInterval: 'Min Interval cannot less than 10000ms',
  emailInvalid: 'Invalid Email',
};

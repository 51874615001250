import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private datePipe: DatePipe, private router: Router, private dialog: MatDialog) {}

  public replaceUrlChar(url: string): string {
    return url.replace(AppConstant.CT_SLAPSH, AppConstant.CT_DOUBLE_ASTRIK);
  }

  public replaceBackUrlChar(url: string): string {
    return url.replace(AppConstant.CT_DOUBLE_ASTRIK, AppConstant.CT_SLAPSH);
  }

  public replaceUrlOtherChar(url: string): string {
    return url.replace(AppConstant.CT_HASH, AppConstant.CT_DOUBLE_CARROT);
  }

  public replaceBackUrlOtherChar(url: string): string {
    return url.replace(AppConstant.CT_DOUBLE_CARROT, AppConstant.CT_HASH);
  }

  formatCSVTitleName(title: string): string {
    const today = this.datePipe.transform(new Date(), AppConstant.CT_DATEPIPE_FORMAT_FULL_2);
    const titleName = `${title}_${today}`;
    return titleName;
  }

  public convertDateTime(datetime): string {
    return datetime !== AppConstant.CT_NULL
      ? this.datePipe.transform(datetime, AppConstant.CT_DATEPIPE_FORMAT_FULL)
      : AppConstant.CT_NULL;
  }

  public convertDatePipeFormatYYMMDD(datetime): string {
    return datetime !== AppConstant.CT_NULL
      ? this.datePipe.transform(datetime, AppConstant.CT_DATEPIPE_FORMAT_YYYYMMDD)
      : AppConstant.CT_NULL;
  }

  public convertDateTimeDB(datetime): string {
    return datetime !== AppConstant.CT_NULL
      ? this.datePipe.transform(datetime, AppConstant.CT_DATEPIPE_FORMAT_FULL_DB)
      : AppConstant.CT_NULL;
  }

  public convertStatusText(params) {
    if (params !== undefined) {
      const data =
        params === AppConstant.CT_TRUE.toString() || params === AppConstant.CT_TRUE
          ? AppConstant.CT_TRUE
          : AppConstant.CT_FALSE;
      return data ? AppConstant.CT_YES_TEXT : AppConstant.CT_NO_TEXT;
    }
  }

  public convertDateCode(datetime): string {
    return datetime !== AppConstant.CT_NULL
      ? this.datePipe.transform(datetime, AppConstant.CT_DATEPIPE_FORMAT_DATECODE)
      : AppConstant.CT_NULL;
  }

  public displayDateCode(datecode): string {
    // sample datecode= 20190820
    return datecode !== AppConstant.CT_NULL
      ? datecode.substring(0, 4) + '-' + datecode.substring(4, 6) + '-' + datecode.substring(6)
      : AppConstant.CT_NULL;
  }

  public verifyNumber(data): number {
    return data === AppConstant.CT_EMPTY || data === AppConstant.CT_NULL || data === AppConstant.CT_UNDEFINED
      ? AppConstant.CT_NUM_0
      : data;
  }

  public verifyString(data): string {
    return data === AppConstant.CT_EMPTY || data === AppConstant.CT_NULL || data === AppConstant.CT_UNDEFINED
      ? AppConstant.CT_EMPTY
      : data;
  }

  public generateStringNumberArray(count) {
    return Array(count)
      .fill(0)
      .map((x, i) => (i + 1 < 10 ? '0' + (i + 1) : i + 1));
  }

  public generateNumberArray(count) {
    return Array(count)
      .fill(0)
      .map((x, i) => i + 1);
  }

  public routeToHome(): void {
    sessionStorage.setItem(AppConstant.SS_TITLE, AppConstant.TN_HOME);
    this.router.navigateByUrl(AppConstant.RP_HOME);
  }

  public verifyStringAll(data): string {
    return data === AppConstant.CT_EMPTY || data === AppConstant.CT_NULL || data === AppConstant.CT_UNDEFINED
      ? AppConstant.CT_ALL
      : data;
  }

  public checkStringValid(text: string): boolean {
    return text !== AppConstant.CT_NULL &&
      text !== AppConstant.CT_EMPTY &&
      text !== AppConstant.CT_UNDEFINED &&
      text !== AppConstant.CT_NULL_TEXT
      ? AppConstant.CT_TRUE
      : AppConstant.CT_FALSE;
  }

  public setupDialogDeleteData(component): any {
    return this.dialog.open(component, {
      data: [
        AppConstant.CT_EMPTY,
        AppConstant.CT_DELETE_RECORD,
        AppConstant.CT_NO,
        AppConstant.CT_YES,
        AppConstant.CT_NUM_2
      ],
      width: AppConstant.CT_PERCENTAGE_100,
      height: AppConstant.CT_PERCENTAGE_50,
      autoFocus: AppConstant.CT_FALSE,
      restoreFocus: AppConstant.CT_FALSE
    });
  }

  public setupDialogData(component, data, height): any {
    return this.dialog.open(component, {
      data: data,
      width: AppConstant.CT_PERCENTAGE_100,
      height: height,
      autoFocus: AppConstant.CT_FALSE,
      restoreFocus: AppConstant.CT_FALSE
    });
  }

  public getCartonId(value: string): string[] {
    const cartonLabel = value.substring(4, value.length);
    const tempCartonLabel = cartonLabel.split(AppConstant.CT_COMMA);
    const numOfCarton =
      Number(tempCartonLabel[2]) < 10
        ? `00${tempCartonLabel[2]}`
        : Number(tempCartonLabel[2]) < 100
        ? `0${tempCartonLabel[2]}`
        : `${tempCartonLabel[2]}`;
    return [tempCartonLabel[3] + AppConstant.CT_DASH + numOfCarton, tempCartonLabel[4]];
  }

  public verifyControlValue(controlValue, field): string {
    return controlValue ? controlValue[field] : AppConstant.CT_EMPTY;
  }

  public castAgDate(data, field: string) {
    if (this.checkStringValid(data)) {
      const formatDate = this.convertDateTimeDB(data[field]);
      const tempDate = formatDate.split(' ');
      return tempDate[0];
    } else {
      return AppConstant.CT_NULL;
    }
  }

  public onInitScrollToTop(): void {
    const scrollElem = document.querySelector('#top');
    scrollElem.scrollIntoView();
  }

  public scrollTo(where: string, smooth: boolean): void {
    const scrollElem = document.querySelector(where);
    if (smooth) {
      scrollElem.scrollIntoView({ behavior: 'smooth' });
    } else {
      scrollElem.scrollIntoView();
    }
  }
}

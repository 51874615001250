import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '@app-helpers/data.service';
import { AppConstant } from '@app/app.constant';
import 'ag-grid-enterprise';

@Component({
  selector: 'app-grid-setup',
  templateUrl: './grid-setup.component.html',
  styleUrls: ['./grid-setup.component.scss']
})
export class GridSetupComponent implements OnInit {
  rowSelection = 'multiple';
  gridApi: any;
  gridColumnApi: any;
  defaultColDef = AppConstant.CFG_AG_GRID_COL_DEF;
  selectedData = [];
  @Input() columnDefs = [];
  @Input() viewList = [];
  @Input() gridCss: number;
  @Input() rowGroupPanelShow = '';
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() copy: EventEmitter<any> = new EventEmitter();
  @Output() selectedDataArray: EventEmitter<any> = new EventEmitter();
  AppConstant = AppConstant;

  constructor(private dataService: DataService) {}

  ngOnInit() {}

  public onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  public onRowSelected(event) {
    const data = [];
    event.api.getSelectedNodes().map(res => {
      data.push(res['data']);
    });
    this.selectedData = data;
    this.selectedDataArray.emit(this.selectedData);
  }

  sendEdit(event) {
    this.edit.emit(event);
  }

  sendCopy(event) {
    this.copy.emit(event);
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.gridApi.setQuickFilter(filterValue);
  }

  public gridReset() {
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
    this.gridApi.deselectAll();
  }

  public onExport(title) {
    const params = {
      fileName: this.dataService.formatCSVTitleName(title),
      columnGroups: true
    };
    this.gridApi.exportDataAsCsv(params);
  }

  public onRemoveRecord(): void {
    this.gridApi.updateRowData({ remove: this.selectedData });
  }

  public onAddRecord(field): void {
    this.gridApi.updateRowData({ add: [field] });
  }

  public onDeleteRecord(data): void {
    this.gridApi.updateRowData({ remove: [data] });
  }

  public getLatestRowData(): any {
    const rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  public onStopEditing(): void {
    this.gridApi.stopEditing();
  }

  public ClearData() {
    this.gridApi.setRowData([]);
  }
}

import { BecomePartnerDialogComponent } from './_helpers/become-partner-dialog/become-partner-dialog.component';
import { RequestDemoDialogComponent } from './_helpers/request-demo-dialog/request-demo-dialog.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@app-directives/translate.pipe';
import { UuidV1Service } from '@app-helpers/uuid-v1.service';
import { AttributeStandardComponent } from '@app/_shared/_helpers/attribute-standard/attribute-standard.component';
import { GridSetupComponent } from '@app/_shared/_helpers/grid-setup/grid-setup.component';
import { HeaderStandardComponent } from '@app/_shared/_helpers/header-standard/header-standard.component';
import { MaterialModule } from '@app-shared/material.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SpinnerComponent } from '@app/_shared/_helpers/spinner/spinner.component';
import { AgGridModule } from 'ag-grid-angular';
import { NumericEditorComponent } from '@app/_shared/_helpers/numeric-editor/numeric-editor.component';
import { StatusIconComponent } from './_helpers/status-icon/status-icon.component';
import { NumericDirective } from '@app/_directives/numeric.directive';
import { TooltipPipe } from '@app/_directives/tooltip.pipe';
import { AutoFocusDirective } from '@app/_directives/auto-focus.directive';
import { MaterialElevationDirective } from '@app/_directives/material-elevation.directive';
import { FooterComponent } from './_helpers/footer/footer.component';
import { BackToTopComponent } from './_helpers/back-to-top/back-to-top.component';
import { HeaderComponent } from '@app/_shared/_helpers/header/header.component';
import { RouterModule } from '@angular/router';
import { HeaderCarouselComponent } from '@app/_shared/_helpers/header/header-carousel/header-carousel.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { CareerDialogComponent } from '@app/_shared/_helpers/career-dialog/career-dialog.component';
import { CustomCopySnackbarComponent } from '@app/_shared/_helpers/snackbar-design/custom-copy-snackbar/custom-copy-snackbar.component';
import { NewsEventsDialogComponent } from '@app/_shared/_helpers/news-events-dialog/news-events-dialog.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { PageUnderMaintenanceComponent } from './_helpers/page-under-maintenance/page-under-maintenance.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@app-env/environment';

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    AgGridModule.withComponents([]),
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ClickOutsideModule,
    NgxMatIntlTelInputModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SpinnerComponent,
    TranslatePipe,
    CommonModule,
    HeaderComponent,
    HeaderCarouselComponent,
    HeaderStandardComponent,
    GridSetupComponent,
    AttributeStandardComponent,
    NumericEditorComponent,
    StatusIconComponent,
    NumericDirective,
    TooltipPipe,
    AutoFocusDirective,
    MaterialElevationDirective,
    FooterComponent,
    BackToTopComponent,
    ClickOutsideModule,
    CareerDialogComponent,
    CustomCopySnackbarComponent,
    NewsEventsDialogComponent,
    NgxMatIntlTelInputModule,
    PageUnderMaintenanceComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    RequestDemoDialogComponent,
    BecomePartnerDialogComponent
  ],
  providers: [UuidV1Service, {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.captchaProd.siteKey,
    } as RecaptchaSettings,
  }],
  declarations: [
    SpinnerComponent,
    TranslatePipe,
    HeaderComponent,
    HeaderCarouselComponent,
    HeaderStandardComponent,
    GridSetupComponent,
    AttributeStandardComponent,
    NumericEditorComponent,
    StatusIconComponent,
    NumericDirective,
    TooltipPipe,
    AutoFocusDirective,
    MaterialElevationDirective,
    FooterComponent,
    BackToTopComponent,
    CareerDialogComponent,
    CustomCopySnackbarComponent,
    NewsEventsDialogComponent,
    PageUnderMaintenanceComponent,
    RequestDemoDialogComponent,
    BecomePartnerDialogComponent
  ],
  entryComponents: [
    NumericEditorComponent,
    StatusIconComponent,
    CareerDialogComponent,
    CustomCopySnackbarComponent,
    NewsEventsDialogComponent,
    RequestDemoDialogComponent,
    BecomePartnerDialogComponent
  ]
})
export class SharedModule {}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UuidV1Service {
  public getUuidV1String() {
    let d = new Date().getTime();
    const uuid = 'xxxx-xxxx-4xxx-yxxx-xxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}

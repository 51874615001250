import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandlerService {
  public handleErrorObservable(error: Response | any) {
    return throwError(error.message || error);
  }

  public extractData(response) {
    if (response.returnCode > 0) {
      throw new Error(response.returnMessage);
    }
    return response;
  }

  public extractData2(response) {
    if (response.ReturnCode > 0) {
      throw new Error(response.ReturnMessage);
    }
    return response;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConstant } from '@app/app.constant';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent implements OnInit, OnDestroy {
  windowScrolled: boolean;

  constructor() {}

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
  }

  scroll = (event: any): void => {
    const number = event.srcElement.scrollTop;
    if (number > AppConstant.CFG_START_SCROLL_HEIGHT) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  scrollToTop(): void {
    const scrollElem = document.querySelector('#top');
    scrollElem.scrollIntoView({behavior: 'smooth'});
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
}
